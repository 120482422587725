<script setup lang="ts">
	import type { Quote } from "~/types/Quote";
	import { useI18n } from "vue-i18n";

	const { t, locale } = useI18n();

	useHead({
		title: `${t("home.seo.title")}`,
		meta: [
			{
				name: "description",
				content: t("home.seo.description"),
			},
			{
				name: "keywords",
				content: t("home.seo.keywords"),
			},
			{
				property: "og:title",
				content: t("home.seo.title"),
			},
			{
				property: "og:description",
				content: t("home.seo.og_description"),
			},
			{
				property: "og:image",
				content: t("home.seo.og_image"),
			},
			{
				property: "og:url",
				content: t("home.seo.og_url"),
			},
			{
				name: "twitter:card",
				content: "summary_large_image",
			},
			{
				name: "robots",
				content: "index, follow",
			},
		],
		link: [
			{
				rel: "canonical",
				href: t("home.seo.canonical_url"),
			},
		],
	});

	const { data, error } = await useLarafetch<{
		quote: Quote;
	}>(`/api/v1/${locale.value}/public/home`);
</script>

<template>
	<div class="overflow-hidden">
		<HomeLandingHero v-if="data?.quote" :quote="data.quote" />
		<HomeCardSection />
		<svg class="fill-current text-base-300" viewBox="0 0 1440 320">
			<path
				d="m0 288 48-16c48-16 144-48 240-74.7 96-26.3 192-48.3 288-32 96 15.7 192 69.7 288 85.4 96 16.3 192-5.7 288 0 96 5.3 192 37.3 240 53.3l48 16H0Z"
			/>
		</svg>
	</div>
	<HomeWhyMeSection />
</template>
