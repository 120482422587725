<script setup>
	import { onMounted, onUnmounted, ref } from "vue";
	import anime from "animejs";
	import useMobile from "@/composables/useMobile";

	const cards = ref([]);
	const simpleCardsRef = ref([]);

	onMounted(() => {
		observeCards();
		window.addEventListener("scroll", handleScroll);
	});

	onUnmounted(() => {
		window.removeEventListener("scroll", handleScroll);
	});

	const animateDiv = () => {
		if (!isMobile) {
			anime({
				targets: ".moving-div",
				width: divWidth.value,
				translateX: divX.value, // Aggiorna la posizione X in base allo scroll
				easing: "linear",
				duration: 800,
			});
		}
	};

	const { isMobile } = useMobile();

	const scrollY = ref(0);
	const divWidth = ref(0); // Traccia la larghezza del div
	const divX = ref(0); // Traccia la posizione X di partenza per l'animazione del div

	const handleScroll = () => {
		const scrollHeight =
			document.documentElement.scrollHeight - window.innerHeight;
		const currentScroll = window.scrollY;
		const halfwayPoint = scrollHeight / 2;

		if (currentScroll <= halfwayPoint) {
			// Fase di espansione
			const scrollShare = currentScroll / halfwayPoint;
			divWidth.value = scrollShare * (window.innerWidth * 4);
			divX.value = 0; // Parte da sinistra
		} else {
			// Fase di contrazione oppure di espansione inversa
			const scrollShare = (currentScroll - halfwayPoint) / halfwayPoint;
			divWidth.value = (1 - scrollShare) * (window.innerWidth * 4);
			divX.value = window.innerWidth - divWidth.value; // Calcola la nuova posizione X per partire da destra
		}

		animateDiv();
	};

	const observeCards = () => {
		if (typeof window !== "undefined") {
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry, index) => {
						if (entry.isIntersecting) {
							animateCard(entry.target, index);
							console.log("observered");
							observer.unobserve(entry.target);
						}
					});
				},
				{
					rootMargin: "0px",
					threshold: 0.5,
				}
			);

			simpleCardsRef.value.forEach((card) => {
				observer.observe(card.$el);
			});
		}
	};

	function animateCard(cardElement, index) {
		anime({
			targets: cardElement.querySelectorAll(".title .cover"),
			translateX: "100%",
			delay: index * 300, // Aumenta il ritardo per ogni pezzo di testo
			duration: 1200,
			easing: "easeInOutQuad",
		});
		anime({
			targets: cardElement.querySelectorAll(".description .cover"),
			translateX: "100%",
			delay: index * 300 * 2, // Aumenta il ritardo per ogni pezzo di testo
			duration: 1200,
			easing: "easeInOutQuad",
		});
	}

	const simpleCards = ref([
		{
			// img: "https://images.unsplash.com/photo-1542372041-620dfb05997c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1229&q=80",
			img: "https://images.unsplash.com/photo-1565106430482-8f6e74349ca1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
			icon: "🌐",
		},
		{
			// img: "https://images.unsplash.com/photo-1606823616265-3c84de4cfcff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
			img: "https://images.unsplash.com/photo-1581291519195-ef11498d1cf2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
			icon: "🎨",
		},
		{
			// img: "https://images.unsplash.com/photo-1586892478407-7f54fcec5b89?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
			img: "https://images.unsplash.com/photo-1657812160299-6b656decd5b1?q=80&w=2067&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
			icon: "🔍",
		},
	]);
</script>

<template>
	<KinesisContainer :duration="2000" class="w-full h-full">
		<div class="relative sm:mt-28">
			<div v-if="isMobile" class="fixed left-0 z-5 top-24">
				<div
					class="w-full h-64 border-b-8 border-l-8 border-r-8 border-base-300 z-5 moving-div bg-secondary"
					style="min-width: 0px"
				></div>
			</div>
			<div
				class="container grid grid-cols-1 gap-12 py-26 sm:py-20 md:py-2 px-4 md:px-auto mx-auto duration-500 transform bg-transparent md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 sm:pt-24 md:pt-24 lg:pt-32 xl:pt-32 2xl:pt-32 3xl:pt-96 place-items-center"
			>
				<KinesisElement
					v-for="(simpleCard, index) in simpleCards"
					:key="index"
					:strength="5"
					type="depth"
				>
					<CardSimpleCard
						:img="simpleCard.img"
						ref="simpleCardsRef"
						:alt="$t('home.cards.' + (index + 1) + '.title')"
					>
						<template #title>
							{{ $t("home.cards." + (index + 1) + ".title") }}
						</template>
						<template #icon>
							{{ simpleCard.icon }}
						</template>
						<template #description>
							{{ $t("home.cards." + (index + 1) + ".description") }}
						</template>
					</CardSimpleCard>
				</KinesisElement>
			</div>
		</div>
	</KinesisContainer>
</template>
