<script setup lang="ts">
import anime from "animejs";
// import VTypical from "vue-typical";
import type { Quote } from "~/types/Quote";
import CirclesBackground from "@/components/Home/CirclesBackground.vue";
import { defineAsyncComponent, onMounted, onUnmounted, ref } from "vue";
import useMobile from "~/composables/useMobile";
import Letterize from "letterizejs"; // Replace with the correct type if available

const VTypical = defineAsyncComponent(() => 
  import("vue-typical").then(m => m.default).catch(err => {
    console.error("Failed to load VTypical", err);
    return { render: () => null }; // Return a dummy component on failure
  })
);

const props = defineProps<{
    quote: Quote;
}>();

const card = ref(null);

type TextItem = {
  cover: Element | null;
};
const texts = ref<TextItem[]>([
    { cover: null },
    { cover: null },
]);

const { isMobile } = useMobile();


const quoteRef = ref<HTMLElement | null>(null);



const animateHeroPhrase = () => {
    const target = new Letterize({
        targets: "#yourDreamsOnline",
    });

    anime.timeline({
        targets: target.listAll,
        delay: anime.stagger(50),
        loop: true,
    })
    .add({
        translateY: -5,
    })
    .add({
        translateY: 0,
    });
}

const animateAllAppears = () => {
    texts.value.forEach((text, index) => {
        // Seleziona e salva il riferimento al div di copertura
        text.cover = document.querySelector(
            `.text-wrapper:nth-child(${index + 1}) .cover`
        );
    });

    // Anima ogni div di copertura uno dopo l'altro
    texts.value.forEach((text, index) => {
        anime({
            targets: text.cover,
            translateX: "100%",
            delay: index * 300 * 2, // Aumenta il ritardo per ogni pezzo di testo
            duration: 1200,
            easing: "easeInOutQuad",
        });
    });
}

const handleScroll = () => {
  const quoteAnimation = anime({
    targets: quoteRef.value,
    translateX: 800,
    duration: 4000,
    autoplay: false,
  });

  const scrollPercent = window.innerHeight / 2;
  quoteAnimation.seek((scrollPercent / 100) * quoteAnimation.duration);
};

onMounted(() => {
    // if (typeof window !== 'undefined') {
    //     import('letterizejs').then(({ default: Letterize }) => {
    //         animateHeroPhrase(Letterize)
    //     });
    // }
    animateHeroPhrase()
    animateAllAppears()
    if (quoteRef.value) {
        window.addEventListener('scroll', handleScroll);
    }
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
})
</script>

<template>
    <!-- <div ref="quoteContainer">
        <div ref="quoteRef">
            ciao sono fezz
        </div>
    </div> -->
    <KinesisContainer :duration="2000">
        <div
            class="z-20 pt-12 overflow-hidden text-base-content bg-gradient-to-r from-base-100 to-secondary"
        >
            <CirclesBackground v-if="!isMobile"/>

            <div
                class="container flex my-32 flex-wrap items-center h-full px-4 mx-auto  md:flex-row"
                style="height: 35rem;"
            >
                <!--Left Col-->
                <div
                    class="z-50 flex flex-col items-start justify-center w-full text-center md:w-3/5 md:text-left"
                >
                    <p
                        class="z-20 flex w-full text-lg text-center uppercase select-none"
                    >
                        &nbsp;
                        <VTypical
                            class="mb-8 font-medium underline text-base-content blink decoration-primary decoration-2 underline-offset-4"
                            :steps="[
                                ' Web development',
                                1200,
                                ' Freelancing',
                                1200,
                                ' Software development',
                                1200,
                                ' Tailored solutions',
                                1200,
                            ]"
                            :loop="Infinity"
                        />
                    </p>
                    <h1
                        id="yourDreamsOnline"
                        ref="yourDreamsOnlineRef"
                        class="z-20 text-5xl font-bold leading-tight text-left sm:text-center text-accent"
                    >
                        Your dreams, online
                    </h1>
                    <div
                        class="z-20 mt-2 mb-8 text-2xl italic tracking-widest text-center"
                    >
                        {{ $t("home.landing.hero.phrase") }} 🚀
                    </div>

                    <p
                    
                        class="z-20 p-2 text-2xl leading-normal md:p-4 md:mx-8 md:mb-8 text-base-content rounded-xl"
                    >
                        {{ props.quote.text }}

                        <br />
                        - {{ props.quote.author }}
                    </p>
                </div>

                <!--Right Col-->
                <div class="z-20 w-full sm:my-12 md:my-24 md:w-2/5">
                    <KinesisElement :strength="5" type="depth">
                        <KinesisContainer>
                            <KinesisElement :strength="10" type="depth">
                                <div
                                    ref="card"
                                    class="flex p-4 rounded-lg shadow-lg bg-base-100 shadow-primary-focus"
                                >
                                    <div
                                        class="float-left w-3/4 mx-2 text-left text-base-content h-100 text-md"
                                    >
                                        <div class="flex my-2 text-2xl">
                                            <!-- class="mb-8 font-medium underline text-base-content blink decoration-primary decoration-4 underline-offset-4" -->

                                            <h2
                                                class="font-medium text-center underline text-wrapper text-accent decoration-secondary decoration-4 underline-offset-4"
                                            >
                                                {{
                                                    $t(
                                                        "home.landing.hero.card.title"
                                                    )
                                                }}
                                                👋
                                                <div class="border-l-8 cover"/>
                                            </h2>
                                        </div>
                                        <p
                                            class="text-lg text-wrapper"
                                        >
                                            {{ $t(
                                                'home.landing.hero.card.description.html'
                                            ) }}
                                            <div class="border-l-8 cover"/>

                                        </p>
                                        <!-- <div
                                            class="flex justify-between m-4 text-base-content place-items-center"
                                        > -->
                                            <!--bottom-link icon="fa-brands fa-instagram" href="https://www.instagram.com/fezz_dev/">
                                            Instagram
                                        </!--bottom-link>
                                        <bottom-link-- icon="fa-brands fa-twitter" href="https://www.twitter.com">
                                            Twitter
                                        </bottom-link-->
                                            <!--bottom-link icon="fa-brands fa-linkedin" href="https://www.linkedin.com/in/federico-palcich/">
                                            Linkedin
                                        </bottom-link-->
                                        <!-- </div> -->
                                    </div>
                                    <div
                                        class="relative float-right w-1/4 h-full"
                                    >
                                        <div class="grid items-center justify-center avatar">
                                            <div
                                                class="m-3 rounded-full shadow-lg bg-base-100 shadow-primary ring-4 ring-primary ring-offset-primary-content ring-offset-4"
                                            >
                                                <img
                                                    src="assets/fezz_no_bg.png"
                                                    alt="Federico Palcich"
                                                    class="backdrop-contrast-100 backdrop-opacity-100"
                                                />
                                            </div>
                                        </div>
                                        <!--

                                        <img src="assets/fezz_profile.png" alt="Federico Palcich" class="rounded-lg" />
                                    --></div>
                                </div>
                            </KinesisElement>
                        </KinesisContainer>
                    </KinesisElement>
                </div>
            </div>
            <div class="relative z-20 -mt-12 lg:-mt-24">
                <svg
                    viewBox="0 0 1428 174"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <g
                            transform="translate(-2.000000, 44.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        >
                            <path
                                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                opacity="0.100000001"
                            ></path>
                            <path
                                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                opacity="0.100000001"
                            ></path>
                            <path
                                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                id="Path-4"
                                opacity="0.200000003"
                            ></path>
                        </g>
                        <g
                            class="fill-current text-base-100"
                            transform="translate(-4.000000, 76.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                        >
                            <path
                                d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
                            ></path>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
        <div class="relative z-50 w-full h-0 bg-base-100">
            <svg class="fill-current text-base-100" viewBox="0 0 1100 174">
                <g>
                    <path
                        transform="rotate(90 571.487 -92.8479)"
                        d="m359.83171,-664.63034l84.66196,1143.56499l338.64778,0l-84.66196,-1143.56499l-338.64778,0z"
                    />
                </g>
            </svg>
        </div>
    </KinesisContainer>
</template>