<script setup lang="ts">
	import { ref, onMounted } from "vue";
	import anime from "animejs";

	const titleRef = ref(null);
	const paragraphRef = ref(null);

	const observerOptions = {
		threshold: 1,
	};

	const createObserver = (
		elementRef: Ref<HTMLElement | null>,
		animationCallback: (entry: IntersectionObserverEntry) => void
	) => {
		if (typeof window !== "undefined") {
			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						animationCallback(entry);
						observer.unobserve(entry.target);
					}
				});
			}, observerOptions);

			onMounted(() => {
				if (elementRef.value) {
					observer.observe(elementRef.value);
				}
			});
		}
	};

	const animateElement = (element: IntersectionObserverEntry, animationDetails: object) => {
		anime({
			targets: element,
			opacity: [0, 1],
			...animationDetails,
			easing: "easeOutExpo",
			duration: 3000,
		});
	};

	createObserver(titleRef, (target: IntersectionObserverEntry) => {
		console.log('animating titleRef')
		animateElement(target, { translateY: [-50, 0] });
	});
	createObserver(paragraphRef, (target: IntersectionObserverEntry) => {
		animateElement(target, { translateX: [-100, 0] });
	});
</script>

<template>
	<KinesisContainer :duration="2000">
		<div
			ref="sectionRef"
			class="h-full bg-base-300 border-b-8 border-secondary overflow-hidden"
		>
			<div class="max-w-4xl mx-auto p-8">
				<h1
					ref="titleRef"
					class="text-5xl font-bold text-center text-accent mb-4"
				>
					{{ $t("home.whyme.phrase.title") }} 🚀
				</h1>
				<p
					ref="paragraphRef"
					class="text-xl text-center text-base-content mb-8"
				>
					{{ $t("home.whyme.phrase.text") }} 🌟.
				</p>
				<KinesisElement
					class="grid grid-cols-1 gap-8 py-4 md:grid-cols-2 h-full"
					:strength="5"
					type="depth"
				>
					<KinesisContainer>
						<!-- The Others: Improved UI -->
						<KinesisElement
							:strength="10"
							type="depth"
							class="bg-base-100 shadow-lg flex-1 flex flex-col h-full rounded-lg p-6 transition-all hover:shadow-2xl"
						>
							<h2 class="text-2xl font-semibold text-secondary mb-4">
								{{ $t("home.whyme.others.title") }} 🏢
							</h2>
							<ul class="text-base list-disc pl-5 text-base-content">
								<li class="mb-2">🤯 {{ $t("home.whyme.others.1") }}.</li>
								<li class="mb-2">⏱️ {{ $t("home.whyme.others.2") }}.</li>
								<li class="mb-2">🛠️ {{ $t("home.whyme.others.3") }}.</li>
								<li class="mb-2">💬 {{ $t("home.whyme.others.4") }}.</li>
							</ul>
						</KinesisElement>
					</KinesisContainer>
					<KinesisContainer>
						<!-- Us: Improved UI -->
						<KinesisElement
							:strength="10"
							type="depth"
							class="bg-base-100 shadow-lg flex-1 flex flex-col rounded-lg p-6 transition-all hover:shadow-2xl"
						>
							<h2 class="text-2xl font-semibold text-secondary mb-4">
								{{ $t("home.whyme.me.title") }} 👨‍💻
							</h2>
							<ul class="text-base list-disc pl-5 text-base-content">
								<li class="mb-2">🎯 {{ $t("home.whyme.me.1") }}.</li>
								<li class="mb-2">🔍 {{ $t("home.whyme.me.2") }}.</li>
								<li class="mb-2">👂 {{ $t("home.whyme.me.3") }}.</li>
								<li class="mb-2">🌱 {{ $t("home.whyme.me.4") }}.</li>
							</ul>
						</KinesisElement>
					</KinesisContainer>
				</KinesisElement>
			</div>
		</div>
	</KinesisContainer>
	<div class="relative w-full h-full min-h-screen">
		<div
			class="absolute inset-0 h-full w-full p-4 pattern-diagonal-lines pattern-current pattern-bg-transparent pattern-opacity-20 pattern-size-32"
		></div>
		<div class="relative"></div>
	</div>
</template>
