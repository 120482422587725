<!-- <script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import Square from "@/Components/Decoration/Square.vue";

const windowWidth = ref(window.innerWidth);
const squares = ref([]);
const fixedHeight = 400; // esempio in pixel

onMounted(() => {
    squares.value = generatePositions(windowWidth.value);
});

function generatePositions(width) {
    const positions = [];
    const squareSize = 50; // Dimensione fissa dei quadrati
    const gridSize = squareSize * 2; // Spazio tra i quadrati
    const columns = Math.floor(width / gridSize);
    // Numero di quadrati basato sulla larghezza dinamica
    const numSquares = Math.floor((width / window.innerWidth) * 12);

    for (let i = 0; i < numSquares; i++) {
        let position;
        do {
            position = {
                x: Math.floor(Math.random() * columns) * gridSize,
                y:
                    Math.floor(Math.random() * (fixedHeight / gridSize)) *
                    gridSize,
                // Puoi anche includere l'altezza qui se necessario
            };
        } while (
            positions.some(
                (pos) => pos.x === position.x && pos.y === position.y
            )
        );
        positions.push(position);
    }
    return positions;
}
</script>

<template>
    <div class="flex flex-wrap">
        <div v-for="(square, index) in squares" :key="index">
            <Square
                :right-offset="square.rightOffset"
                :style="{ top: `${square.y}px`, left: `${square.x}px` }"
            />
        </div>
    </div>
</template> -->

<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
// import Square from "@/Components/Decoration/Square.vue";

const windowWidth = ref(typeof window !== "undefined" ? window.innerWidth : 0);
const squares = ref([]);
const fixedHeight = 700; // esempio in pixel

onMounted(() => {
    squares.value = generatePositions(windowWidth.value);
});

watch(windowWidth, (newWidth) => {
    squares.value = generatePositions(newWidth);
});

function generatePositions(width) {
    const positions = [];
    const squareSize = 50; // Dimensione fissa dei quadrati
    const gridSize = squareSize * 2; // Spazio tra i quadrati
    const columns = Math.floor(width / gridSize);
    // Numero di quadrati basato sulla larghezza dinamica
    const numSquares = Math.floor(
        (width / (typeof window !== "undefined" ? window.innerWidth : 1)) * 12
    );

    for (let i = 0; i < numSquares; i++) {
        let position;
        do {
            position = {
                x: Math.floor(Math.random() * columns) * gridSize,
                y:
                    Math.floor(Math.random() * (fixedHeight / gridSize)) *
                    gridSize,
                // Puoi anche includere l'altezza qui se necessario
            };
        } while (
            positions.some(
                (pos) => pos.x === position.x && pos.y === position.y
            )
        );
        positions.push(position);
    }
    return positions;
}
</script>

<template>
    <div class="flex flex-wrap">
        <div v-for="(square, index) in squares" :key="index">
            <DecorationSquare
                :right-offset="square.rightOffset"
                :style="{ top: `${square.y}px`, left: `${square.x}px` }"
            />
        </div>
    </div>
</template>
