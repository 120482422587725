<script setup>
import { ref, onMounted, computed } from "vue";
import anime from "animejs";

const props = defineProps({
    rightOffset: Number,
});

const dimensions = Math.floor(Math.random() * 10) + 10;
const top = Math.floor(Math.random() * 5) * 10 + 1;
const right = Math.floor(Math.random() * 10) + props.rightOffset;
const strength = Math.floor(Math.random() * 100) + 1;
const opacity = ref(0.25); // Usa ref per reattività

const squareRef = ref(null);

onMounted(() => {
    // Assegna un listener al movimento del mouse per tutto il documento
    document.addEventListener("mousemove", (e) => {
        const { clientX, clientY } = e;
        // Calcola la distanza dal centro del documento all'elemento
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;
        const distanceX = Math.abs(centerX - clientX) / centerX;
        const distanceY = Math.abs(centerY - clientY) / centerY;
        const distance = Math.sqrt(
            distanceX * distanceX + distanceY * distanceY
        );

        // Aggiorna l'opacità in base alla distanza
        // opacity.value = 1 - Math.min(distance, 0.5); // Assicurati che l'opacità non vada sotto 0.5
    });
});

const style = computed(() => ({
    height: `${dimensions}rem`,
    width: `${dimensions}rem`,
    top: `${top}%`,
    right: `${right}%`,
    "z-index": 0,
    "box-shadow": "0px 0px 5px 5px",
    opacity: opacity.value, // Utilizza l'opacità reattiva
}));

const kinesisEffect = () => {
    if (squareRef.value) {
        anime({
            targets: squareRef.value,
            scale: [0.5, 1.5], // Effetto pulsante
            duration: 100,
            loop: true,
            direction: "alternate",
            easing: "easeInOutSine",
        });
    }
};

onMounted(kinesisEffect);
</script>

<template>
    <KinesisElement
        :strength="strength - dimensions / 4"
        class="absolute rounded-full bg-base-content"
        :style="style"
        ref="squareRef"
        type="translate"
    />
</template>
